export const PERCENTAGE_DISCOUNT_TYPE_CONSTANT = 1;

export const EXIT_GATING_DEFAULT_IMG_URL =
  'https://cdn.dotpe.in/longtail/gif/76/036EtrqP.jpeg';

export const EXIT_GATING_TEMPLATE_TYPE = {
  BG_IMG: 'bg-img',
  SIDE_IMG: 'side-img',
  NO_IMG: 'no-img',
};

export const EXIT_GATING_GREEDY_MESSAGE = {
  BG_IMG: "Stop! Here's a Surprise",
  NO_IMG: "Wait! You're Leaving Empty-Handed",
  SIDE_IMG: "Don’t go away, here's a surprise!",
};

export const EXIT_GATING_CONFIG_KEY = 'exitGatingCofig';
